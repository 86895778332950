<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Rubrics Criteria</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postRubric" class="row p-2">
                            <div class="col-lg-12 form-group mb-3">
                                <label class="mont-font fw-600 font-xsss" for="">Criteria Name</label>
                                <input v-model="form.name" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-lg-12 form-group mb-3">
                                <label class="mont-font fw-600 font-xsss" for="">Criteria Score</label>
                                <input v-model="form.score" type="number" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-lg-12 form-group mb-3">
                                <label class="mont-font fw-600 font-xsss" for="">Criteria Description</label>
                                <textarea v-model="form.description" required class="form-control text-grey-900 font-xsss fw-600" rows="3"></textarea>
                            </div>
                            <div class="col-xl-12 col-lg-12 form-group text-right my-4">
                                <router-link :to="{name: 'RubricsCoursesCriteriaAdmin', params: {idCourse: paramsId, idRubric: rubricId}}" class="btn btn-outline-warning mr-4">Cancel</router-link>
                                <input type="Submit" class="btn btn-warning text-white" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "RubricsCoursesCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            rubricId: this.$route.params.idRubric,
            form: {
                name: '',
                description: '',
                score: '',
            },
        }
    },
    methods:{
        async postRubric() {
            var data = {
                rubrics : this.rubricId,
                name : this.form.name,
                score : this.form.score,
                description : this.form.description,
                slug : this.form.slug,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/rubrics/criteria`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Rubrics Criteria!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'RubricsCoursesCriteriaAdmin', params: {idCourse: this.paramsId, idRubric: this.rubricId} })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Rubrics Criteria!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>
